function ErrorComponent() {
    return (
        <div className="ErrorComponent">
            <br/>
            <br/>
            <br/>
            <br/>
            <h2>404 Not Found</h2>
        </div>
    )
}

export default ErrorComponent