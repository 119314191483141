import React from "react";

export default function ExperienceComponent() {

    return(
        <div className={'container'}>
            <hr/>
            <div className="testimonialCol" style={{paddingTop:'1px'}}>
                <h4 className="smText regular d-block"
                    style={{textAlign: 'left', fontWeight: 'bolder'}}>Experience</h4>
                <h4 className="xsTitle bold" style={{textAlign: 'justify'}}>Junior Java Full-Stack
                    Developer, <p
                        style={{fontSize: '11px', fontStyle:'italic'}}>@
                        Exsentra Innovation Limited, Bangladesh</p></h4>

            </div>
            <div style={{textAlign: 'left'}}>
                <ul>
                    <li style={{listStyle: 'inside' , listStyleType:'square'}}>
                        Developed and maintained high-performance Java-based applications using Spring and Spring Boot
                    </li>
                    <li style={{listStyle: 'inside' , listStyleType:'square'}}>
                        Played a key role in the development of a feature-rich application that increased user
                        satisfaction by
                        15%.
                    </li>
                    <li style={{listStyle: 'inside' , listStyleType:'square'}}>
                        Implemented RESTful APIs, Third party APIs, testing APIs, and ensured secure API endpoints
                    </li>
                    <li style={{listStyle: 'inside', listStyleType:'square'}}>
                        Participated in optimizing Java applications for database design and retrieval times, resulting in a 10% improvement in performance
                    </li>
                </ul>

            </div><br/>

        </div>
    )
}