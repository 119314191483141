import BannerComponent from "./BannerComponent";
import KeySkillComponent from "./KeySkillComponent";
import CertificateComponent from "./CertificateComponents";
import FeatureWorkComponents from "./FeatureWorkComponents";
import RecentPostComponent from "./RecentPostComponent";
import ExperienceComponent from "./Experience Component";


export default function HomeComponent() {
    return(
        <>
            <BannerComponent/>
            <KeySkillComponent/>
            <ExperienceComponent/>
            <CertificateComponent/>
            <FeatureWorkComponents/>
            <RecentPostComponent/>
        </>

    )
}